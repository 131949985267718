<template>
  <div class="jobdetail">
    <div class="jobdetail-content">
      <div class="jobdetail-header">
        <div class="jobdetail-header-left">
          <div class="jobdetail-header-title">
            <div class="job-name">{{ info.zwmc }}</div>
            <div class="job-salary">
              {{ info.cMy == "1" ? "面议" : setsalary(info.cDyb, info.cDye) }}
            </div>
          </div>
          <div class="jobdetail-header-view">
            <div class="jobdetail-header-item">
              学历要求：<span>{{ info.cXl }}</span>
            </div>
            <div class="jobdetail-header-item">
              是否紧缺：<span>{{ info.cJZ == 0 ? "否" : "是" }}</span>
            </div>
            <div class="jobdetail-header-item">
              更新时间：<span>{{
                info.oF_UPDATE_DATE
                  ? stringToDates(info.oF_UPDATE_DATE)
                  : "一个月前"
              }}</span>
            </div>
          </div>
          <div class="jobdetail-header-view">
            <div class="jobdetail-header-item">
              工作地点：<span>{{ info.coM_CT_CODE }}</span>
            </div>
            <div class="jobdetail-header-item">
              招聘人数：<span>{{ info.cZprs }}</span>
            </div>
            <div class="jobdetail-header-item">
              专业要求：<span>{{ info.cZy }}</span>
            </div>
          </div>
          <div class="jobdetail-header-view">
            <div
              class="jobdetail-header-welfare"
              v-if="info.oF_FLZL_QT != null && info.oF_FLZL_QT != ''"
            >
              福利待遇：<span>{{ info.oF_FLZL_QT }}</span>
            </div>
          </div>
        </div>
        <div class="jobdetail-header-right">
          <div class="release-time">
            发布时间:
            {{ info.oF_REDATE ? stringToDates(info.oF_REDATE) : "一个月前" }}
          </div>
          <!-- <div class="follow-Collection" v-if="usertype == 1">
            <div class="follow" @click="follow" :class="{ active: iscss }">
              <i :class="iscss ? 'el-icon-star-off' : 'el-icon-star-on'"></i
              >{{ iscss ? "加入收藏" : "已收藏" }}
            </div>
            <div class="Collection"></div>
          </div> -->
          <div class="apply-btn" v-if="!userinfo || usertype == 1">
            <el-button
              :type="!info.isSend ? 'primary' : 'info'"
              @click="info.isSend == 1 ? '' : Addresumes(info.id)"
              >{{ info.isSend == 1 ? "已申请" : "申请职位" }}</el-button
            >
          </div>
        </div>
      </div>
      <div class="jobdetail-contain">
        <div class="jobdetail-introduce">
          <div class="introduce-item">
            <div class="introduce-h2">职位信息</div>
            <div class="introduce-text">
              <h2>岗位要求：</h2>
              <p v-html="info.cGwyq"></p>
            </div>
          </div>
          <!-- <div class="introduce-item">
            <div class="introduce-h2">联系方式</div>
            <div class="introduce-text">
              <div class="introduce-text-list">
                <div>联系电话：</div>
                <div>{{ midname(info.coM_PHONE) }}</div>
                <div>{{ midname(info.coM_MPHONE) }}</div>
              </div>
              <div class="introduce-text-list">
                <span>联系人：</span>{{ midname(info.coM_LINKMAN) }}
              </div>
              <div class="introduce-text-list">
                <span>联系地址：</span>{{ info.cAddress }}
              </div>
            </div>
          </div> -->
          <!-- <div class="introduce-item">
            <div class="introduce-h2">公司信息</div>
            <div class="introduce-text">
              <p>
                {{ info.coM_INTRODUCT }}
              </p>
            </div>
          </div> -->
          <div class="introduce-item">
            <div class="introduce-h2">地图导航</div>
            <div class="introduce-text">
              <!-- <img src="../../assets/img/map.png" alt="" /> -->
              <maps
                ref="maps"
                :mapheight="'300px'"
                :keyword="info.cAddress"
              ></maps>
            </div>
          </div>
        </div>
        <div class="jobdetail-company">
          <div class="company-card">
            <div class="company-hd">
              <div class="company-logo">
                <img :src="setcompanylogo(info.coM_LOGO)" />
              </div>
              <div class="company-name">{{ info.coM_NAME }}</div>
            </div>
            <div class="company-body">
              <div>
                行&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业：<span>{{
                  info.coM_INDUSTRY
                }}</span>
              </div>

              <div v-if="info.coM_SCALE != null">
                企业规模：<span>{{ info.coM_SCALE }}</span>
              </div>
            </div>
            <div class="company-btn">
              <el-button
                type="primary"
                @click="
                  goto('/company', {
                    id: cid,
                    zid: zphid,
                  })
                "
                >查看其它岗位</el-button
              >
            </div>
          </div>
          <!-- <div class="post-card">
            <div class="post-title">其他岗位</div>
            <div class="post-cell">
              <div
                class="position-item"
                v-for="(item, index) in positiondata"
                @click="
                  jobid = item.oF_ID;
                  Getposidetails();
                "
                :key="index"
              >
                <div class="position-hd">
                  <div class="post-name">{{ item.zwmc }}</div>
                  <div class="post-salary">
                    {{
                      item.cMy == 1
                        ? "面议"
                        : setsalary(item.cDyb, item.cDye)
                    }}
                  </div>
                </div>
                <div class="position-bd">
                  <div>{{ item.oF_WORKAREAName }}</div>
                  <div>|</div>
                  <div>
                    {{ item.oF_GZJY ? item.oF_GZJY + "经验" : "不限经验" }}
                  </div>
                  <div>|</div>
                  <div>
                    {{
                      item.oF_EDU_REQ === null
                        ? "不限"
                        : item.oF_EDU_REQ + "学历"
                    }}
                  </div>
                  <div>|</div>
                  <div>{{ item.oF_POSI_NATURE }}</div>
                </div>
                <div class="position-card">
                  <div class="card-img">
                    <img :src="setcompanylogo(item.COM_LOGO)" alt="" />
                  </div>
                  <div class="card-body">
                    <div class="card-name">{{ item.com_name }}</div>
                    <div class="card-posthot">
                      <b>{{ item.counts }}</b
                      >热招岗位
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="ad-card">
            <img src="../../assets/img/ad5.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import {
  Getposidetail,
  Getindexsearch,
  Addresume,
  Addfav,
  Cancelfav,
} from "@/api/home";
import {
  onlineposicompanyjobsdetail,
  onlineposicompanylist,
  sendzphperson,
} from "@/api/company";
import { isnull } from "@/assets/js/index";
import global from "@/assets/js/globalconfig";
import utils from "@/assets/js/utils";
import maps from "@/components/map";
export default {
  components: {
    maps,
  },
  data() {
    return {
      defimgurl: global.baseimgurl,
      form: {
        name: "",
        region: "",
        industry: "",
      },
      form: {
        searchKey: "",
        region: "",
        industry: "",
        field: "",
        field1: "", //职位类型
        field2: "", //公司行业
        field3: "", //工作经验
        field4: "", //学历要求
        field5: "", //薪资要求
        field6: "", //融资阶段
        field7: "", //公司规模
        order: "", //排序
        pageNumber: 1,
        pageSize: 3,
      },
      info: {},
      jobid: "",
      iscss: false,
      postdata: {},
      positiondata: [],
      companydata: [],
      jobinfo: {},
      usertype: 0,
      userinfo: "",
      zphid: "",
      cid: "",
    };
  },
  created() {
    this.jobid = this.$route.query.id;
    this.zphid = this.$route.query.zphid;
    this.cid = this.$route.query.cid;
    if (!isnull(localStorage.getItem("userinfo"))) {
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
      this.usertype = this.userinfo.userType;
    }
    this.Getposidetails();
  },
  methods: {
    midname(data) {
      if (isnull(localStorage.getItem("token"))) {
        if (isnull(data)) {
          return data;
        }
        return utils.formatName(data);
      } else {
        return data;
      }
    },
    goto(url, data) {
      this.resolvewin(url, data, 1);
    },
    stringToDates(data) {
      if (isnull(data)) {
        return "";
      }
      return utils.stringToDate(data);
    },
    follow() {
      this.iscss = !this.iscss;
      var rdata = new Array();
      var userinfo = { peR_ID: "" };
      if (!isnull(localStorage.getItem("userinfo"))) {
        userinfo = JSON.parse(localStorage.getItem("userinfo"));
      }
      if (this.Required()) {
        return;
      }
      if (!this.iscss) {
        var par = {
          id: this.info.oF_ID,
          uid: userinfo.peR_ID,
        };
        rdata = Addfav(par);
      } else {
        var par = {
          id: this.info.favId,
        };
        rdata = Cancelfav(par);
      }
      rdata.then((res) => {
        if (res.success) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    setsalary(a, b) {
      var data = [];
      if (!isnull(a)) {
        if (a < 100 && a > 0) {
          data.push(a + "k");
        } else if (a >= 1000) {
          data.push(a / 1000 + "k");
        } else {
          data.push(a);
        }
      }
      if (!isnull(b)) {
        if (b < 100 && b > 0) {
          data.push(b + "k");
        } else if (b >= 1000) {
          data.push(b / 1000 + "k");
        } else {
          data.push(b);
        }
      }
      if (data.length === 0) {
        return "面议";
      } else {
        return data.join("-");
      }
    },
    Getposidetails() {
      var _this = this;
      if (isnull(_this.jobid)) {
        return false;
      }

      onlineposicompanyjobsdetail({
        id: _this.jobid,
        zid: this.userinfo.peR_ID ? _this.userinfo.peR_ID : 0,
      }).then((res) => {
        if (res.success) {
          _this.info = res.data[0];
          // if (!isnull(_this.info.isFav)) {
          //   _this.iscss = _this.info.isFav === 0 ? true : false;
          // }
          // _this.onlineposicompanylist();
        } else {
          _this.$message.error(res.msg);
        }
      });
    },

    onlineposicompanylist() {
      var _this = this;
      var par = {
        id: _this.cid,
        uid: _this.zphid,
        zid: _this.userinfo
          ? _this.userinfo.peR_ID
            ? _this.userinfo.peR_ID
            : 0
          : 0,
        PageSize: 3,
        PageNumber: 1,
      };
      onlineposicompanylist(par).then((res) => {
        if (res.success) {
          _this.positiondata = res.data.rows;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    setcompanylogo(url) {
      return isnull(url)
        ? require("@/assets/img/logo.png")
        : this.defimgurl + url;
    },
    //简历投递
    Addresumes(id) {
      var _this = this;
      if (!_this.userinfo) {
        this.resolvewin("/login", { type: 1, isback: true }, 1);
        return;
      }
      var userinfo = { peR_ID: "" };
      if (!isnull(localStorage.getItem("userinfo"))) {
        userinfo = JSON.parse(localStorage.getItem("userinfo"));
      }
      if (this.Required()) {
        return;
      }
      var par = {
        job_id: id,
        per_id: userinfo.peR_ID,
      };
      sendzphperson(par).then((res) => {
        if (res.success) {
          _this.$message({
            message: "简历投递成功",
            type: "success",
          });
          _this.info.isSend = 1;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    Required() {
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
      if (
        !userinfo.peR_NAME ||
        !userinfo.peR_GENDER ||
        !userinfo.peR_BIRTHDAY ||
        !userinfo.peR_ADDRESS ||
        !userinfo.peR_CELLPHONE
      ) {
        this.$confirm(
          "您还没有完善个人基础信息(姓名，性别，出生日期等等),赶快去完善吧!",
          "温馨提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.resolvewin("/usercenter", { type: 1 }, 1);
          })
          .catch(() => {});
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.job-search {
  position: relative;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;
  margin-bottom: 16px;
  .search-content {
    width: 1200px;
    margin: 0px auto;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    .search {
      flex: 1;
      width: 100%;
      .el-form {
        .el-form-item ::v-deep {
          margin-bottom: 0;
          .el-form-item__content {
            display: flex;
            align-items: stretch;
            .search-input {
              display: flex;
              border: 1px solid #eee;
              .el-input {
                .el-input__inner {
                  border: none;
                  width: 520px;
                }
              }
              .el-select {
                .el-input__inner {
                  border: none;
                  width: 140px;
                }
                .el-input::before {
                  content: "";
                  position: absolute;
                  width: 1px;
                  height: 18px;
                  left: 0px;
                  top: 11px;
                  background: #ddd;
                }
              }
            }
            .search-btn {
              .el-button {
                background: #66b1ff;
                border-radius: 0;
                height: 100%;
                padding: 12px 30px;
                width: 150px;
              }
            }
          }
        }
      }
    }
    .search-hottel {
      display: flex;
      align-items: center;
      font-size: 14px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }
      span {
        font-size: 18px;
        color: #66b1ff;
      }
    }
  }
  .select-content {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .select-cell {
      .el-form {
        .el-form-item ::v-deep {
          margin-bottom: 0;
          .select-input {
            .el-select {
              margin-right: 60px;
              .el-input__inner {
                width: 110px;
                padding-left: 0;
                border: none;
              }
            }
          }
        }
      }
    }
    .select-clear {
      font-size: 14px;
      color: #787878;
      line-height: 40px;
    }
  }
}
.jobdetail-content {
  position: relative;
  width: 1200px;
  margin: 0px auto;
  padding: 16px 0;
  .jobdetail-header {
    padding: 24px;
    background: #fff;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    align-items: stretch;
    margin-bottom: 12px;
    .jobdetail-header-left {
      width: 80%;
      .jobdetail-header-title {
        line-height: 32px;
        font-size: 18px;
        color: #333;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .job-name {
          font-weight: bold;
        }
        .job-salary {
          font-weight: bold;
          color: #ff7630;
        }
      }
      .jobdetail-header-view {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        line-height: 26px;
        .jobdetail-header-item {
          min-width: 250px;
          text-align: left;
          font-size: 14px;
          color: #898989;
          display: flex;
          span {
            color: #666;
          }
        }
        .jobdetail-header-welfare {
          text-align: left;
          font-size: 14px;
          color: #898989;
          display: flex;
          span {
            color: #666;
            margin-right: 16px;
          }
        }
      }
      .jobdetail-header-view:last-child {
        margin-bottom: 0;
      }
    }
    .jobdetail-header-right {
      flex: 1;
      width: 20%;
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      .release-time {
        width: 100%;
        font-size: 14px;
        color: #787878;
        margin-bottom: 12px;
        height: 26px;
        line-height: 26px;
      }
      .follow-Collection {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 32px;
        line-height: 32px;
        .follow {
          display: flex;
          align-items: center;
          color: #ff7630;
          cursor: pointer;
          i {
            font-size: 22px;
            margin-right: 8px;
          }
        }
        .active {
          color: #66b1ff;
        }
      }
      .apply-btn {
        width: 100%;
        align-self: flex-end;
        text-align: right;
      }
    }
  }
  .jobdetail-contain {
    display: flex;
    align-items: stretch;
    position: relative;
    .jobdetail-introduce {
      padding: 8px 24px;
      background: #fff;
      flex: 1;
      width: 100%;
      .introduce-item {
        position: relative;
        padding: 16px 0;
        border-top: 1px solid #eee;
        .introduce-h2 {
          padding-left: 24px;
          position: relative;
          font-size: 18px;
          font-weight: bold;
          color: #000;
          text-align: left;
          margin-bottom: 12px;
        }
        .introduce-h2::before {
          width: 5px;
          left: 0;
          top: 2px;
          bottom: 2px;
          border-radius: 2.5px;
          background: #66b1ff;
          content: "";
          position: absolute;
        }
        .introduce-text {
          position: relative;
          text-align: left;
          font-size: 14px;
          color: #898989;
          padding-left: 24px;
          display: flex;
          flex-direction: column;
          h2 {
            font-size: 16px;
            font-weight: normal;
          }
          p {
            margin: 0px;
            padding: 0px;
            line-height: 26px;
          }
          .introduce-text-list {
            display: flex;
            align-items: center;
            margin-top: 12px;
            color: #666;
            div {
              margin-right: 10px;
            }
            span {
              display: block;
              width: 72px;
              font-size: 14px;
              color: #898989;
              text-align: right;
            }
            a {
              text-decoration: none;
              color: #ff7630;
              font-weight: bold;
            }
          }
          img {
            width: 100%;
          }
        }
      }
      .introduce-item:first-child {
        border-top: none;
      }
    }
    .jobdetail-company {
      width: 380px;
      margin-left: 16px;
      .company-card {
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 20px;
        .company-hd {
          display: flex;
          align-items: center;
          padding-bottom: 12px;
          border-bottom: 1px solid #eee;
          margin-bottom: 12px;
          .company-logo {
            width: 64px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            img {
              width: 100%;
            }
          }
          .company-name {
            font-size: 18px;
            color: #000;
            font-weight: bold;
          }
        }
        .company-body {
          text-align: left;
          font-size: 15px;
          color: #898989;
          border-bottom: 1px solid #eee;
          div {
            padding-bottom: 16px;
          }
          span {
            color: #666;
          }
        }
        .company-btn {
          padding-top: 16px;
        }
      }
      .post-card {
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 20px;
        .post-title {
          padding-left: 24px;
          position: relative;
          font-size: 18px;
          font-weight: bold;
          color: #000;
          text-align: left;
          margin-bottom: 12px;
        }
        .post-title::before {
          width: 5px;
          left: 0;
          top: 2px;
          bottom: 2px;
          border-radius: 2.5px;
          background: #66b1ff;
          content: "";
          position: absolute;
        }
        .post-cell {
          .position-item {
            padding: 16px;
            position: relative;
            background: #f2f2f2;
            margin-bottom: 16px;
            .position-hd {
              font-size: 16px;
              color: #409eff;
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
              align-items: center;
              .post-salary {
                font-size: 16px;
                color: #ff6600;
                font-weight: bold;
              }
            }
            .position-bd {
              font-size: 12px;
              color: #787878;
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              .desc {
                height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
              }
              div {
                margin-bottom: 6px;
              }
              span {
                padding: 0 4px;
                border-right: 1px solid #eee;
              }
              span:first-child {
                padding-left: 0;
              }
              span:last-child {
                border: none;
              }
            }
            .position-card {
              display: flex;
              align-items: center;
              text-align: left;
              padding-top: 12px;
              border-top: 1px dashed #ddd;
              .card-img {
                width: 32px;
                line-height: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                margin-right: 12px;
                background: #fff;
                border-radius: 2px;
                img {
                  width: 100%;
                }
              }
              .card-body {
                flex: 1;
                .card-name {
                  font-size: 14px;
                  color: #333;
                }
                .card-posthot {
                  font-size: 12px;
                  color: #787878;
                  b {
                    color: #409eff;
                  }
                }
              }
            }
          }
          .position-item:last-child {
            margin-bottom: 0;
          }
        }
      }
      .ad-card {
        display: flex;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>